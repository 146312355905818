<template>
  <div class="box">
    <!-- 顶部图片 -->
    <div class="topBg">
      <div class="integral">
        我的积分:<span style="font-weight: 700">{{ integralNum }}</span
        >分
      </div>
    </div>
    <!-- tabs切换 -->
    <van-tabs :active="type" sticky @change="CateIdClick">
      <van-tab
        v-for="item in tabList"
        :key="item.CateId"
        :title="item.Name"
        :name="item.CateId"
        color="#ff4500"
        background="#ff4500"
      >
      </van-tab>
    </van-tabs>
    <!-- 列表 -->
    <!-- 没有更多了 -->
    <van-empty v-if="!list.length" description="─── 暂无商品列表 ───" />
    <div class="lists" v-else>
      <div
        class="list"
        v-for="item in list"
        :key="item.id"
        @click="gotoUrl('/integral/detail/' + item.GoodsId + '&' + order)"
      >
        <div class="listImg">
          <img :src="item.ImgUrl" style="width: 100%; height: 100%" />
        </div>
        <div class="listTitle">
          <span>{{ item.Name }}</span>
        </div>
        <div class="listLabel">
          <span>{{ item.Amount }}分/{{ item.MUnit }}</span>
          <span>{{ item.CateName }}</span>
        </div>
      </div>
    </div>
    <!-- 购物车 -->
    <div class="shopping" @click="shoppingClick()" v-if="shoppongShow">
      <img
        src="../../assets/shop/shopping.png"
        alt=""
        style="width: 60%; margin: 12px 17%"
      />
    </div>
    <div>
      <van-popup
        v-model="shoppingShow"
        round
        position="bottom"
        :style="{ height: '70%' }"
      >
        <!-- 商品列表 -->
        <div class="goodsLists">
          <div
            class="goodsList"
            v-for="item in shoppingList"
            :key="item.GoodsId"
          >
            <div style="width: 32%">
              <img
                :src="item.GoodsImgUrl"
                style="width: 110px; height: 100px; margin: 10px"
              />
            </div>
            <div style="width: 65%">
              <div class="flex">
                <span style="color: #01a0fb; font-weight: 700">{{
                  item.GoodsName
                }}</span>
                <span>{{ item.Price }}/{{ item.MUnit }}</span>
              </div>
              <div class="flex">
                <span style="color: #ee0c24">¥{{ item.Amount }}</span>
                <span>
                  <van-stepper
                    v-model="item.GoodsNum"
                    theme="round"
                    min="0"
                    button-size="22"
                    disable-input
                    @change="Goodschange(item)"
                    style="display: inline-block"
                  />
                </span>
              </div>
              <div class="flex">
                <button @click="goodsDelete(item.OGoodsId)" class="delete">
                  删除
                </button>
              </div>
            </div>
          </div>
          <van-empty
            v-if="!shoppingList.length"
            description="─── 暂无商品列表 ───"
          />
        </div>
        <!-- 结算按钮 -->
        <div class="button">
          <button @click="orderClick()">结 算</button>
        </div>
      </van-popup>
    </div>
    <!-- 收货地址 -->
    <van-popup
      v-model="addressShow"
      round
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div class="address">
        <van-radio-group v-model="checked">
          <van-radio name="0">
            <div>
              <div class="radioBox">
                <span>自提</span>
              </div>
            </div>
          </van-radio>
          <van-radio
            :name="item.BindId"
            v-for="item in addressList"
            :key="item.BindId"
          >
            <div>
              <div class="radioBox">
                <span>收件人:{{ item.Name }}</span
                ><span>联系电话:{{ item.Mobile }}</span>
              </div>
              <div style="font-size: 14px; padding-top: 5px">
                地址:{{ item.Addr }}
              </div>
            </div>
          </van-radio>
        </van-radio-group>
      </div>
      <div class="button">
        <button @click="PlaceOrder">支 付</button>
      </div>
      <div class="integralBox">
        <van-radio-group v-model="integralCk" direction="horizontal">
          <van-radio name="1">会员积分({{ MeCurIntegral }}分)</van-radio>
          <van-radio name="2">党员积分({{ PmCurIntegral }}分)</van-radio>
          <van-radio name="3">志愿者积分({{ VolCurIntegral }}分)</van-radio>
        </van-radio-group>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  WxGetGoodsPage,
  WxGetGoodsSortList,
  WxGetGoodsCateList,
  WeGetMemberByOpenID,
} from "@/api/RealInfo";
import {
  WeGenerateOrder,
  WeGetMyOrderPage,
  WeGetOrderGoodsList,
  WePlaceOrder,
  WeDeleteOrderGoods,
  WeSaveOrderService,
  WeGetMyBindInfoPage,
} from "@/api/shop";
import { getOpenId, setOpenId } from "@/utils/auth";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
Vue.use(Toast);
Vue.use(Dialog);
export default {
  data() {
    return {
      type: 20, //tabs选中
      tabList: [], //tabs类型
      list: [], //商品列表
      integralNum: 0,
      shoppingList: [], //购物车列表
      shoppingShow: false, //购物车弹窗
      order: "", //订单
      addressShow: false, //收货地址
      shoppongShow: false, //购物车按钮
      addressList: [], //收货地址
      checked: "0", //收货地址选择
      integralCk: "1", //积分选择
      MeCurIntegral: "", //会员当前积分
      PmCurIntegral: "", //党员当前积分
      VolCurIntegral: "", //志愿者当前积分（时间币）
      totalNum: 0, //总商品数
      totalGold: 0, //总金额
    };
  },
  mounted() {
    // setOpenId("oas2d4qNkpHqYBS0NlA4glPsVR1k"); //测试openid
    this.getTabsList();
    this.getList();
    // this.getMemberByOpenID();
    this.getUserOrder();
  },
  methods: {
    // 选择商品
    orderClick() {
      this.addressShow = true;
      WeGetMyBindInfoPage({ openID: getOpenId() }).then((res) => {
        if (res.data.count == 0) {
          Dialog.confirm({
            title: "提醒",
            message: "您还没有添加收货地址,是否前往添加!",
          })
            .then(() => {
              if (window.__wxjs_environment === "miniprogram") {
                window.wx.miniProgram.navigateTo({
                  url: "../my/user/oldManList/index?name='收货地址'",
                });
              } else {
                alert("请在小程序中打开");
              }
            })
            .catch(() => {
              return false;
            });
        } else {
          this.addressList = res.data.data;
        }
      });
    },
    // 步进器选择
    Goodschange(row) {
      WeSaveOrderService({
        //添加订单商品
        OpenID: getOpenId(),
        OrderId: this.order,
        GoodsId: row.GoodsId,
        GoodsNum: row.GoodsNum,
      }).then((res) => {
        Toast(row.GoodsName + ":" + row.GoodsNum);
        this.getshoppingList(this.order);
      });
    },
    // 获取订单情况
    getUserOrder() {
      WeGetMyOrderPage({ shopId: 74, openID: getOpenId(), status: 1 }).then(
        (res) => {
          if (res.data.code != 0) {
            Toast.fail(res.data.msg);
            this.shoppongShow = false;
          } else {
            if (res.data.count == 0) {
              // 没有订单 生成订单
              WeGenerateOrder({ ShopId: 74, OpenID: getOpenId() }).then(
                (res) => {
                  this.getshoppingList(res.data.data);
                  this.order = res.data.data;
                  this.shoppongShow = true;
                }
              );
            } else {
              // 有订单使用现有订单
              this.getshoppingList(res.data.data[0].OrderId);
              this.order = res.data.data[0].OrderId;
              this.shoppongShow = true;
            }
          }
        }
      );
    },
    // 获取购物车列表
    getshoppingList(row) {
      this.totalNum = 0;
      this.totalGold = 0;
      WeGetOrderGoodsList({ openID: getOpenId(), orderId: row }).then((res) => {
        this.shoppingList = res.data.data;
        for (let i = 0; i < this.shoppingList.length; i++) {
          this.totalNum += this.shoppingList[i].GoodsNum;
          this.totalGold += this.shoppingList[i].Amount;
        }
      });
    },
    // 购物车点击
    shoppingClick() {
      this.shoppingShow = true;
    },
    // 切换分类
    CateIdClick(row) {
      this.type = row;
      this.getList();
    },
    // 获取不同分类列表
    getTabsList() {
      WxGetGoodsCateList({ shopType: 24 }).then((res) => {
        this.tabList = res.data.data;
      });
    },
    // 获取列表
    getList() {
      var cateId = this.type;
      WxGetGoodsPage({ shopId: 74, cateId: cateId }).then((res) => {
        this.list = res.data.data;
      });
    },
    // 获取会员信息
    getMemberByOpenID() {
      WeGetMemberByOpenID({ openID: getOpenId() }).then((res) => {
        if (res.data.code == 0) {
          var data = res.data.data;
          this.integralNum =
            data.MeCurIntegral + data.PmCurIntegral + data.VolCurIntegral;
          this.MeCurIntegral = data.MeCurIntegral;
          this.PmCurIntegral = data.PmCurIntegral;
          this.VolCurIntegral = data.VolCurIntegral;
        } else {
          Toast.fail("您还不是会员,请先注册会员!");
        }
      });
    },
    // 下单结算
    PlaceOrder() {
      if (this.checked == 0) {
        var DeliveryMode = 2;
        this.checked = undefined;
      } else {
        var DeliveryMode = 1;
      }
      if (this.totalGold > this.integralNum) {
        Toast.fail("积分不足,无法兑换商品!");
        return false;
      }
      WePlaceOrder({
        OpenID: getOpenId(),
        ScoreType: this.integralCk,
        BindId: this.checked,
        DeliveryMode: DeliveryMode,
        OrderId: this.order,
        GoodsScore: this.totalGold,
        // GoodsNum:this.totalNum,
      }).then((res) => {
        if (res.data.code == 0) {
          Toast.success("提交成功!");
          this.addressShow = false;
          this.shoppingShow = false;
          this.getMemberByOpenID();
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 删除单独商品
    goodsDelete(row) {
      WeDeleteOrderGoods({
        OpenID: getOpenId(),
        OrderId: this.order,
        OGoodsId: row,
      }).then((res) => {
        if (res.data.code == 0) {
          this.getshoppingList(this.order);
          this.shoppingShow = false;
          Toast.success("删除成功!");
        }
      });
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>
<style scoped>
.box {
  background: #f7f8fa;
  height: 100%;
  min-height: 100vh;
}

/* 背景 */
.topBg {
  position: relative;
  height: 215px;
  width: 100%;
  background: url("../../assets/jfsc.png") no-repeat center;
  background-size: 100% 100%;
}

.topBg .integral {
  position: absolute;
  right: 0px;
  top: 10px;
  background: #fff;
  padding: 10px 22px;
  font-size: 12px;
  color: #e74d18;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

/* 列表 */
.lists {
  display: flex;
  justify-content: left;
  align-items: center;
}

.lists .list {
  width: 45%;
  margin-left: 3.5%;
  background: #fff;
  border-radius: 8px;
  margin-top: 10px;
}

.lists .listImg {
  width: 90%;
  height: 120px;
  text-align: center;
  margin: 13px 5%;
}

.listTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 0px 8px 8px;
}

.listTitle span:nth-child(1) {
  color: #01a5fd;
  font-weight: 700;
}

.listLabel span:nth-child(1) {
  color: #dd001b;
}

.listLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 0px 8px 8px;
}
/* 购物车 */
.shopping {
  position: fixed;
  right: 15px;
  bottom: 25px;
  width: 65px;
  height: 65px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px #f5eeee;
}
.goodsLists {
  width: 95%;
  margin: 10px auto 10px;
  background: #fff;
  height: 57vh;
  border-radius: 10px;
  overflow-y: scroll;
}
.goodsList {
  display: flex;
  justify-items: center;
  /* align-items: center; */
  background: #f7f8fa;
  border-radius: 10px;
  margin-bottom: 7px;
}
.button {
  width: 100%;
  text-align: center;
}
.flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.button button {
  position: fixed;
  bottom: 15px;
  width: 80%;
  height: 40px;
  line-height: 40px;
  background: #01a0fb;
  color: #fff;
  border: none;
  margin: 8px auto 15px;
  border-radius: 6px;
  left: 10%;
}
.goodsList .delete {
  background: #ee0c24;
  border: none;
  color: #fff;
  font-size: 14px;
  border-radius: 7px;
  padding: 5px 13px;
}
.address .van-radio {
  background: #f5f5f5;
  width: 80%;
  margin: 10px auto;
  padding: 6px 15px;
  border-radius: 6px;
}
.address .radioBox {
  display: flex;
  justify-content: space-between;
  width: 70vw;
  font-size: 14px;
}
.integralBox {
  position: absolute;
  bottom: 81px;
  font-size: 12px;
  margin-left: 10%;
}
</style>